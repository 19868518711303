import React, { useEffect, useRef, useState } from 'react';
import useWindow from './useWindow';
import Text from './Text';

export default function Scene() {
  const { dimension } = useWindow();
  const canvas = useRef();
  const prevPosition = useRef(null);
  const [showOverlay, setShowOverlay] = useState(true);

  const lerp = (x, y, a) => x * (1 - a) + y * a;

  useEffect(() => {
    if (dimension.width > 0 && canvas.current) {
      initCanvas();
    }

    // Event-Listener Mausbewegung für das Entfernen des Overlays
    const handleMouseMove = () => {
      setShowOverlay(false); // Text verschwinden lassen
    }
    window.addEventListener('mousemove', handleMouseMove);
    // Event-Listener entfernen
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [dimension]);

  const initCanvas = () => {
    const ctx = canvas.current.getContext('2d');
    if (!ctx) {
      console.error('Canvas context not found!');
      return;
    }
    // Zeichne die schwarze Überlagerung (erst danach)
    ctx.globalCompositeOperation = 'source-over'; // Zurück zum normalen Modus
    ctx.fillStyle = '#11161F'; // Schwarze Überlagerung
    ctx.fillRect(0, 0, dimension.width, dimension.height);
    // Setze auf "destination-out" für Mousemove (dies entfernt nur die schwarze Farbe)
    ctx.globalCompositeOperation = 'destination-out';
  };

  const manageMouseMove = (e) => {
    const { clientX, clientY, movementX = 0, movementY = 0 } = e;
    const numCircles = Math.max(Math.abs(movementX), Math.abs(movementY)) / 10;

    if (prevPosition.current !== null) {
      const { x, y } = prevPosition.current;
      for (let i = 0; i < numCircles; i++) {
        const targetX = lerp(x, clientX, (1 / numCircles) * i);
        const targetY = lerp(y, clientY, (1 / numCircles) * i);
        drawCircle(targetX, targetY, 50);
      }
    }

    prevPosition.current = { x: clientX, y: clientY };
  };

  const drawCircle = (x, y, radius) => {
    const ctx = canvas.current.getContext('2d');
    if (!ctx) return;
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fill();
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* Overlay für die Nachricht */}
      {showOverlay && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'poppins-bold',
            fontSize: '2rem',
            textTransform: 'uppercase',
            zIndex: 20,
            transition: 'opacity 0.5s ease',
            opacity: showOverlay ? 1 : 0, // Ausblenden
          }}
          className="pulse-animation"
        >
          Wipe to Unveil
        </div>
      )}
      <Text width={dimension.width} height={dimension.height} />
      <canvas
        ref={canvas}
        onMouseMove={manageMouseMove}
        height={dimension.height}
        width={dimension.width}
        style={{
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          width: '100%',
          height: '100%',
          zIndex: '1',
          cursor: 'crosshair',
        }}
      />
    </div>
  );
}
