import comingProject from '../public/img/portfolio/outerweb.webp';
import LatestProject from '../public/img/portfolio/delphi.webp';
import CurrentProject from '../public/img/portfolio/reisebuero.webp';
import HobbyProject from '../public/img/portfolio/HobbyProject.webp';
import rocket from '../public/img/portfolio/rocket.svg';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useState } from 'react';

gsap.registerPlugin(ScrollTrigger);
function Portfolio() {
  const [slider, setSlider] = useState(null);
  useEffect(() => {
    if (slider) {
      const sections = gsap.utils.toArray('.outer .slider .section');
      const rockets = gsap.utils.toArray('.rocket-container img');
      const contents = gsap.utils.toArray('.inner .content');

      let portfolioTl = gsap.timeline({
        defaults: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: slider,
          pin: true,
          scrub: 2,
          end: () => '+=' + slider.offsetWidth,
        },
      });

      portfolioTl.to(
        slider,
        {
          xPercent: -75,
        },
        '<'
      );
      // Change BG Slide 2
      if (sections[0]) {
        portfolioTl.to(sections[1], {
          backgroundColor: '#BBDDF2',
          scrollTrigger: {
            trigger: sections[0],
            start: 'right 40',
            end: 'right',
            scrub: true,
          },
        });
      }
      // Change font color Slide 2
      portfolioTl.to(
        [
          contents[1].querySelector('h1'),
          contents[1].querySelector('p'),
          contents[1].querySelector('.tech-stack'),
          contents[1].querySelectorAll('.project-links a'),
        ],
        {
          color: '#0D2F45',
          scrollTrigger: {
            trigger: sections[1],
            start: 'right',
            end: 'right 50',
            scrub: true,
          },
        }
      );
      // Change font color Slide 3
      portfolioTl.to(
        [
          contents[2].querySelector('h1'),
          contents[2].querySelector('p'),
          contents[2].querySelector('.tech-stack'),
          contents[2].querySelectorAll('.project-links a'),
        ],
        {
          color: '#5b3000',
          scrollTrigger: {
            trigger: sections[2],
            start: 'right',
            end: 'right end',
            scrub: true,
          },
        }
      );

      // Change bg Slide 3
      portfolioTl.fromTo(
        sections[2],
        {
          backgroundColor: '#BBDDF2',
        },
        {
          backgroundColor: '#f3e8d6',
          scrollTrigger: {
            // trigger: sections[1],
            // trigger: '.section-project-2',
            // start: 'left center',
            end: 'center',
            scrub: true,
            // markers: true,
          },
        }
      );

      // Change font color Slide 4
      portfolioTl.to(
        [
          contents[3].querySelector('h1'),
          contents[3].querySelector('p'),
          contents[3].querySelector('.tech-stack'),
          contents[3].querySelectorAll('.project-links a'),
        ],
        {
          color: '#2c2218',
          scrollTrigger: {
            trigger: sections[3],
            start: 'left',
            end: 'right 100',
            scrub: true,
          },
        }
      );

      // Change bg Slide 4
      portfolioTl.fromTo(
        sections[3],
        {
          backgroundColor: '#f3e8d6',
        },
        {
          backgroundColor: '#DCF8DC',
          scrollTrigger: {
            start: 'top',
            end: 'center',
            scrub: true,
          },
        }
      );

      // Change bg Slide 4
      // portfolioTl.to(sections[3], {
      //   backgroundColor: '#DCF8DC',
      //   scrollTrigger: {
      //     trigger: sections[2],
      //     end: 'right -50',
      //     scrub: true,
      //   },
      // });

      sections.forEach((stop, index) => {
        portfolioTl
          .from(stop.querySelector('.content'), {
            yPercent: -400,
            opacity: 0,
            scrollTrigger: {
              trigger: stop.querySelector('img'),
              end: 'left 50%',
              containerAnimation: portfolioTl,
              scrub: true,
            },
          })
          .from(
            stop.querySelector('img'),
            {
              xPercent: 40,
              yPercent: -100,
              ease: 'elastic.out(1, 1)',
              scrollTrigger: {
                trigger: stop.querySelector('.content'),
                scrub: 2,
                containerAnimation: portfolioTl,
              },
            },
            '<'
          );
      });

      // Rockets animation
      rockets.forEach((rocket, i) => {
        gsap.from(rocket, {
          xPercent: rocket.dataset.distance,
          scrollTrigger: {
            scrub: 0.3,
          },
        });
      });
    }
  }, [slider]);

  return (
    <>
      <div className="outer">
        <div className="slider" ref={(ref) => setSlider(ref)}>
          <section className="section-project-1 section">
            <div className="inner">
              <img src={comingProject} alt="Current Project" />
              <div className="content">
                <h1>Outerweb (upcoming)</h1>
                <p className="project-description">
                  Building and designing a modern, high-performance website for
                  a web development startup, focusing on sleek aesthetics
                  and seamless functionality. The site will feature a
                  streamlined booking system for packages, ensuring an intuitive
                  and efficient user experience.
                </p>
                <p className="tech-stack">
                  Tech-stack coming soon
                </p>
                <div className="project-links"></div>
              </div>
            </div>
          </section>
          <section className="section-project-2 section">
            <div className="inner">
              <img src={CurrentProject} alt="Current Project" />
              <div className="content">
                <h1>Reisewelt Martin Gerl</h1>
                <p className="project-description">
                  Designed and developed a light-themed website for a travel
                  agency, including the creation of a custom logo, focusing on
                  user-friendly navigation and immersive visual storytelling.
                  The project incorporates dynamic animations for a smooth
                  browsing experience, emphasizing elegance and functionality.
                </p>
                <p className="tech-stack">
                  GatsbyJS - React - Contentful <i>(upcoming)</i> - SASS - GSAP
                  - Lenis - Midjourney - Figma
                </p>
                <div className="project-links">
                  <a
                    href="https://reisewelt-feucht.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Link
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="portfolio section">
            <div className="inner">
              <img src={LatestProject} alt="Current Project" />
              <div className="content">
                <h1>Restaurant Delphi</h1>
                <p className="project-description">
                  Designed and developed the website for Restaurant Delphi, a
                  Greek restaurant based in Vienna. The website combines modern,
                  clean design with traditional elements. With intuitive
                  navigation and a responsive layout, visitors can seamlessly
                  explore the menu. It was a collaborative effort to bring the
                  warmth of Greek hospitality online, inviting guests to
                  experience the flavors of Greece at restaurant Delphi.
                </p>
                <p className="tech-stack">
                  React.js - React-Router-Dom - SASS - GSAP - Figma
                </p>
                <div className="project-links">
                  <a
                    href="https://delphi-hietzing.at/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Link
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="portfolio section">
            <div className="inner">
              <img src={HobbyProject} alt="Current Project" />
              <div className="content">
                <h1>Hobby Project</h1>
                <p className="project-description">
                  A personal music player project that I am working on. Goal of
                  the project is, to build an algorithm for filtering the genres
                  of the songs in the playlists. Currently you can register,
                  sign-in, upload new albums and new songs.
                </p>
                <p className="tech-stack">
                  React.js - React-Router-Dom - TailwindCSS - Node.js/Express.js
                  - Mongoose - JWT - Bcryptjs
                </p>
                <div className="project-links">
                  <a
                    href="https://github.com/nastasiosg/music-player"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GitHub
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="rocket-container">
        <img src={rocket} data-distance="180" alt="Animated Rocket" />
        <img src={rocket} data-distance="260" alt="Animated Rocket" />
        <img src={rocket} data-distance="400" alt="Animated Rocket" />
        <img src={rocket} data-distance="220" alt="Animated Rocket" />
        <img src={rocket} data-distance="340" alt="Animated Rocket" />
        <img src={rocket} data-distance="310" alt="Animated Rocket" />
        <img src={rocket} data-distance="200" alt="Animated Rocket" />
        <img src={rocket} data-distance="395" alt="Animated Rocket" />
      </div>
    </>
  );
}

export default Portfolio;
