import React from 'react';

export default function Text({ width, height }) {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: `${width}px`,
          height: `${height}px`,
          display: 'flex',
          justifyContent: 'center',
          background: '#DCF8DC',
          alignItems: 'center',
          zIndex: -1, // Unter dem Canvas
          fontFamily: 'planeto-outline',
          color: '#FB8F37',
        }}
      >
        <h1
          style={{
            fontSize: '37px',
            fontFamily: 'poppins-bold',
          }}
        >
          scroll ⬇️ to find out more
        </h1>
        <h3
          style={{
            position: 'absolute',
            left: '30px',
            top: '50px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          Class `96
        </h3>
        <h3
          style={{
            position: 'absolute',
            left: '50px',
            top: '100px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          Nürnberg, Germany
        </h3>
        <h3
          style={{
            position: 'absolute',
            left: '100px',
            bottom: '260px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          ⚽️, 🏋🏻‍♂️, 🎶, 👨‍💻, ☕️, 🕺
        </h3>
        <h3
          style={{
            position: 'absolute',
            right: '20px',
            top: '5px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          German/Greek
        </h3>
        <h3
          style={{
            position: 'absolute',
            right: '50px',
            top: '170px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          Communication is 🔑
        </h3>
        <h3
          style={{
            position: 'absolute',
            right: '80px',
            top: '270px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          Frontend: Bridging humans & machines
        </h3>
        <h3
          style={{
            position: 'absolute',
            left: '30px',
            bottom: '25px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          With the right music everything is possible
        </h3>
        <h3
          style={{
            position: 'absolute',
            right: '10px',
            bottom: '105px',
            zIndex: -1,
            fontSize: '30px',
          }}
        >
          Always learning, always creating
        </h3>
      </div>
    </>
  );
}
