import React from 'react';
import Scene from './landing/Scene';

function Landing() {
  return (
    <div className="landing-container">
      <Scene />
    </div>
  );
}

export default Landing;
